import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const DiscardChangeView: FC<{ isDirty: boolean; message?: string }> = ({
  isDirty,
  message
}) => {
  const { t } = useTranslation()
  const Router = useRouter()
  const isDirtyRef = useRef({ isDirty: false, preventChange: false })
  const defaultMessage = message
    ? message
    : t('common:modal:discard_unsaved_changes_full')

  isDirtyRef.current.isDirty = isDirty || false
  const onRouteChangeStart = (as: string) => {
    if (isDirtyRef.current.isDirty) {
      const currentPath = Router.asPath
      if (as !== currentPath) {
        if (confirm(defaultMessage)) return true
        else {
          window.history.pushState(null, '', currentPath)
          Router.events.emit('routeChangeError')
          throw "Abort route change by user's confirmation."
        }
      }
    }
    return true
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [])

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isDirtyRef.current.isDirty) {
        return true
      }
      return undefined
    }
    if (typeof window !== 'undefined') {
      window.onbeforeunload = handleBeforeUnload
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.onbeforeunload = null
      }
    }
  }, [isDirtyRef.current.isDirty])

  return <div />
}

export default DiscardChangeView
